interface DialogProps {
    title?: string;
    description?: string;
    content: React.ReactNode;
    actions: React.ReactNode;
}

export default function Dialog({ title, description, content, actions }: DialogProps) {
    return (
        <div className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        {title && <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>}
                        {description && (
                            <div className="mt-2 mb-4">
                                <p className="text-sm text-gray-500">{description}</p>
                            </div>
                        )}
                        {content}
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 mt-5 sm:flex sm:flex-row-reverse sm:px-6 sm:gap-3">{actions}</div>
        </div>
    );
}
