import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface InfomationAlertProps {
    children: React.ReactNode;
    className?: string;
}

const InformationAlert = ({ children, className }: InfomationAlertProps) => (
    <div className={clsx('bg-warning-300 rounded-lg p-2 flex items-center text-warning-900', className)}>
        <InformationCircleIcon className="h-7 mr-2" />
        <p className="font-medium [&>a]:underline">{children}</p>
    </div>
);

export default InformationAlert;
