import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';

import App from 'App';
import { BASE_PATH } from 'config';
import { store } from 'store';
import { ConfigProvider } from 'contexts/config';

import 'assets/scss/style.scss';
import './index.css';

// ignore the following line in eslint
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';

// ==============================|| REACT DOM RENDER  ||============================== //
const Entry = () => (
    <>
        <Helmet>
            <script type="text/javascript">
                {`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="AJQuXqOLqknVerjUBj1eyI1QcqETkjHv";;analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("${process.env.REACT_APP_SEGMENT_IO_TOKEN}");
  analytics.page();
  }}();`}
            </script>
        </Helmet>
        <Provider store={store}>
            <ConfigProvider>
                <BrowserRouter basename={BASE_PATH}>
                    <App />
                </BrowserRouter>
            </ConfigProvider>
        </Provider>
    </>
);

export default Entry;

ReactDOM.render(<Entry />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
