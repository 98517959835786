import { Box, Typography, Grid, Breadcrumbs, CardMedia, Divider, Dialog, Autocomplete, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { Button } from 'ui-component/extended/Button';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import logosFacebook from 'assets/images/logos_facebook.svg';
import { GET_FACEBOOK_CONNECTION } from 'services/graphQL/queries/fbIntegration';
import { useLazyQuery, useMutation } from '@apollo/client';
import IntegrationDialog from './IntegrationDialog';
import DialogConfirm from 'ui-component/dialogConfirm';
import { DELETE_AGENCY_FACEBOOK_CONNECTION } from 'services/graphQL/mutations/fbIntegration';
import { loadState } from 'utils/storage';
import { GET_USER_ORGANIZATIONS } from 'services/graphQL';
import { useCommonStyles } from 'themes/useCommonStyles';
import clsx from 'clsx';
import { EllipsisVerticalIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import Modal from './Dialog';
import { Transition } from '@headlessui/react';
import DropDown from './DropDown';
import Menu from './Menu';
import InformationAlert from './InformationAlert';
import { TextInput } from '../clients/components/text-input';

interface InregrationType {
    title: string;
    src: string;
    desc: string;
    status: string;
}
const CredentialsTab = () => {
    const theme = useTheme();
    const commonStyles = useCommonStyles();
    const userInfo = loadState('user');
    const organizationId = userInfo.member.organizationId;

    const [organizationName, setOrganizationName] = React.useState('');
    const userId = JSON.parse(`${localStorage.getItem('user')}`);
    const [getUser] = useLazyQuery(GET_USER_ORGANIZATIONS, {
        onCompleted: (res) => {
            setOrganizationName(res.organizations[0].name);
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    React.useEffect(() => {
        getUser({
            variables: {
                user_id: userId.user.id
            }
        });
    }, []);

    return (
        <Box sx={{ p: '0 24px' }}>
            <Breadcrumbs aria-label="breadcrumb">
                {/* <Link className="link" to="/">
                    <FormattedMessage id="venly" />
                </Link> */}
                <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[200], fontFamily: 'Inter', fontWeight: 500 }}>
                    {organizationName}
                </Typography>
                <Link className="link" to="/settings">
                    <FormattedMessage id="menu_settings" />
                </Link>
                <Link className="link" to="/settings">
                    <FormattedMessage id="agency" />
                </Link>
                <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500 }}>
                    <FormattedMessage id="integrations" />
                </Typography>
            </Breadcrumbs>
            <Typography
                mt={3}
                sx={{
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '24px'
                }}
            >
                <FormattedMessage id="credentials" />
            </Typography>
            <Typography
                mt={1}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[600],
                    fontSize: '14px'
                }}
            >
                Credentials allows your workflows to connect to external services.
            </Typography>
            <Divider sx={{ m: '20px 0' }} />
            {/* main content */}
            <div className="flex flex-col gap-3">
                <button className="button max-w-fit" type="button" onClick={() => {}}>
                    Add credentials
                </button>
                <div className="flex flex-col gap-2">
                    <div className="card-border flex gap-2 items-center">
                        <div className="w-14 h-14 k">a</div>
                        <div className="flex flex-col gap-1">
                            <p className="font-semibold text-main">Slack</p>
                            <p className="text-secondary">Update data | created at</p>
                        </div>
                        <EllipsisVerticalIcon className="h-7 ml-auto mr-4 cursor-pointer" onClick={() => {}} />
                    </div>
                </div>
            </div>
            <div className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Add new credentials</h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">Select an app or service to connect to</p>
                            </div>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={[
                                    { label: 'The Godfather', id: 1 },
                                    { label: 'Pulp Fiction', id: 2 }
                                ]}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Movie" />}
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={() => {}}
                    >
                        Deactivate
                    </button>
                    <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {}}
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <Modal
                title="Slack"
                description="Connect your slack account"
                content={
                    <div>
                        <InformationAlert className="mb-10">
                            Need help? Open the doc here <a href="google.com">here</a>
                        </InformationAlert>
                        <p className="label">Api key</p>
                        <TextInput name="Api key" />
                    </div>
                }
                actions={
                    <>
                        <button type="button" className="button" onClick={() => {}}>
                            Deactivate
                        </button>
                        <button type="button" className="button button-secondary" onClick={() => {}}>
                            Cancel
                        </button>
                    </>
                }
            />
            <Menu title={<EllipsisVerticalIcon className="h-7 ml-auto mr-4 cursor-pointer" onClick={() => {}} />} />
        </Box>
    );
};

export default CredentialsTab;
